<template>
    <div class="gst-hotel-amenity d-flex flex-row align-center">
        <div class="gst-hotel-amenity__icon d-flex justify-center align-center">
            <IconRoomAmenity :id="itemWithPrice.id" />
        </div>
        <span class="gst-hotel-amenity__text">
            {{ itemWithPrice.name }}
        </span>
        <BaseTooltip
            v-if="itemWithPrice.tooltip"
            bottom
            :text="itemWithPrice.tooltip"
            class-content="gst-hotel-amenity__tooltip-content"
            class-activator="gst-hotel-amenity__tooltip-activator d-flex align-center justify-center"
            class="gst-hotel-amenity__amenities__tooltip">
            <HelpIcon class="gst-hotel-amenity__help-icon" />
        </BaseTooltip>
    </div>
</template>

<script>
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import HelpIcon from '@tenant/app/assets/icons/help.svg';
    import IconRoomAmenity from '@tenants/ticketmaster/app/modules/hotel/components/icons/IconRoomAmenity';

    export default {
        name: 'HotelAmenity',
        components: {
            BaseTooltip,
            HelpIcon,
            IconRoomAmenity
        },
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            itemWithPrice( ) {
                const { item } = this;
                if ( !item.included && item.price ) {
                    const priceFormatted = this.$options.filters.currencyFilter( item.price, item.currency );
                    const tooltip = `${item.name} ${priceFormatted}`;
                    const name = this.$options.filters.appendCurrencySymbol( item.name, item.currency );

                    return {
                        ...item,
                        name: name,
                        tooltip: tooltip
                    };
                }

                return item;
            }
        }
    };
</script>


<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-hotel-amenity {
        background-color: theme-color( 'white' );
        color: theme-color( 'success' );

        .gst-hotel-amenity__text {
            padding-left: theme-spacing( 2 );
        }

        .gst-hotel-amenity__icon {
            min-height: 18px;

            svg {
                height: 18px;
                width: 18px;
            }
        }

        .gst-hotel-amenity__help-icon {
            height: 18px;
            width: 18px;

            .gst-svg-icon {
                stroke: theme-color( 'tertiary' ) !important;
                transition: all 0.2s ease-in;
            }

            .gst-svg-icon:nth-child(2) {
                fill: transparent !important;
            }
        }

        .gst-hotel-amenity__help-icon:hover {
            .gst-svg-icon {
                stroke: theme-color( 'primary' ) !important;
                transition: all 0.2s ease-in;
            }
        }
    }

    @include mobile-only {
        .gst-hotel-amenity {
            display: flex;
            width: 100%;
            flex: 100%;
        }
    }

    .gst-hotel-amenity__tooltip-content.v-tooltip__content {
        @include border-radius( 'xs' );

        width: auto !important;
        padding: theme-spacing( 4 ) !important;
        background: theme-color( 'white' ) !important;
        color: theme-color( 'quaternary' ) !important;
        box-shadow: 0 2px 6px rgba( 0, 0, 0, 0.25 ) !important;
        opacity: 1 !important;
    }

    .gst-hotel-amenity__tooltip-activator {
        margin-left: auto;
    }
</style>