<template>
    <div class="package-offer__hotel-details u-width-100 flex flex-column">
        <div class="subtitle">
            {{ $t('hotelDetails.title') }}
        </div>
        <div class="details d-flex">
            <ImageMasonryVariant1
                :images="hotel.images.slice( 0, 5 )"
                :images-on-mobile="5"
                :can-open-full-screen="false"
                class="image-masonry px-0" />
            <div class="hotel-info flex-grow-1">
                <div class="hotel-title d-flex">
                    <p>
                        {{ hotel.name }}
                    </p>
                    <StarRating class="d-flex align-center flex-grow-1" :value="stars" />
                </div>
                <div class="venue-distance d-flex align-center">
                    <LocationIcon /> {{ hotel.venueDistance }}
                </div>
                <div class="check-in-out d-flex flex-row">
                    <div class="flex-grow-1">
                        <p>{{ $t('_common:terms.checkIn') }}</p>
                        <p class="check-in-out__datetime">
                            {{ checkInTime }}
                        </p>
                    </div>
                    <div class="flex-grow-1">
                        <p>{{ $t('_common:terms.checkOut') }}</p>
                        <p class="check-in-out__datetime">
                            {{ checkOutTime }}
                        </p>
                    </div>
                </div>
                <div class="amenities d-flex flex-wrap mt-4">
                    <div v-for="(item, key) in hotelAmenities" :key="key" class="amenity">
                        <HotelAmenity :item="item" class="pr-4" />
                    </div>
                    <v-menu
                        content-class="extra-amenities-menu"
                        offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-if="extraHotelAmenities.length"
                                v-bind="attrs"
                                class="amenity extra-amenities d-flex align-center"
                                v-on="on"
                                @mouseenter="extraAmenitiesOpened = true"
                                @mouseleave="extraAmenitiesOpened = false">
                                {{ $t('hotelDetails.extraAmenities', { count: extraHotelAmenities.length}) }}
                            </div>
                        </template>
                        <div>
                            <p v-for="(item, key) in extraHotelAmenities" :key="key">
                                {{ getExtraHotelAmenitiesName( item ) }}
                            </p>
                        </div>
                    </v-menu>
                </div>
            </div>
        </div>
        <div class="description">
            {{ hotel.description }}
        </div>
    </div>
</template>

<script>
    import StarRating from '@core/shared/components/misc/StarRating.vue';
    import ImageMasonryVariant1 from '@tenants/ticketmaster/app/components/images/ImageMasonryVariant1';
    import HotelAmenity from '@tenants/ticketmaster/app/modules/hotel/components/containers/HotelAmenity';
    import LocationIcon from '@tenants/ticketmaster/app/assets/icons/location.svg';

    export default {
        name: 'HotelDetails',
        components: {
            LocationIcon,
            StarRating,
            HotelAmenity,
            ImageMasonryVariant1
        },
        props: {
            hotel: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                extraAmenitiesOpened: false
            };
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.packageOffer.thePackageOffer._components'
        },
        computed: {
            breakPointExtraHotelAmenities ( ) {
                const breakPoint = 12;

                return this.hotel.services.length > breakPoint ?  breakPoint - 1 : breakPoint;
            },
            hotelAmenities() {
                return this.hotel.services.slice( 0, this.breakPointExtraHotelAmenities );
            },
            extraHotelAmenities() {
                return this.hotel.services.slice( this.breakPointExtraHotelAmenities );
            },

            stars( ) {
                const { awards } = this.hotel;

                if ( awards.length && awards[ 0 ].rating ) {
                    return awards[ 0 ].rating;
                }

                return 0;
            },
            checkInTime( ) {
                const { policy } = this.hotel;

                return this.getCheckInOut( {
                    dateTime: policy.checkInDateTime,
                    time: policy.checkInTime
                } );
            },
            checkOutTime( ) {
                const { policy } = this.hotel;

                return this.getCheckInOut( {
                    dateTime: policy.checkOutDateTime,
                    time: policy.checkOutTime
                } );
            }
        },
        methods: {
            getExtraHotelAmenitiesName: function ( item ) {
                if ( !item.included && item.price ) {
                    const priceFormatted = this.$options.filters.currencyFilter( item.price, item.currency );
                    const symbol = this.$options.filters.currencySymbol( item.currency );

                    return `${item.name} (${symbol}) - ${priceFormatted}`;
                }
                return item.name;
            },
            getCheckInOut: function ( { dateTime, time } ) {

                if ( time ) {
                    return this.$t(
                        '_common:terms.dateAndTimeAt',
                        {
                            date: this.$options.filters.date( dateTime, 'dd-MMM-yyyy' ),
                            time: this.$options.filters.date( dateTime, 'HH:mm' )
                        }
                    );
                }
                return this.$options.filters.date( dateTime, 'dd-MMM-yyyy' );
            }
        }
    };
</script>

<style lang="scss">
    @import '@scssVariables';
    @import '@scssMixins';

    .package-offer__hotel-details {
        margin-bottom: theme-spacing( 10 );

        .subtitle {
            line-height: line-height( 'm' );
            margin-bottom: theme-spacing( 2 );
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxs' );
            font-weight: font-weight( 'large' );
            text-transform: uppercase;
        }

        .details {
            .image-masonry {
                width: 50%;
                margin-right: theme-spacing( 4 );
                min-height: 340px;
            }

            .hotel-info {
                position: relative;
                margin-top: theme-spacing( 5 );
                flex: 1;

                .hotel-title {
                    p {
                        line-height: line-height( 'xxxl' );
                        margin-bottom: unset;
                        margin-right: theme-spacing( 1 );
                        color: theme-color( 'quaternary' );
                        font-size: font-size( 'xxl' );
                        font-weight: font-weight( 'large' );
                    }
                }

                .venue-distance {
                    line-height: line-height( 'xl' );
                    margin: theme-spacing( 4, 0, 3, 0 );
                    color: theme-color( 'tertiary' );
                    font-size: font-size( 's' );
                    text-transform: lowercase;

                    svg {
                        margin-bottom: 1px;
                        margin-right: theme-spacing( 1 );
                    }
                }

                .check-in-out {
                    padding: theme-spacing( 1, 4 );
                    border: 1px solid theme-color( 'senary' );
                    color: theme-color( 'tertiary' );
                    font-size: font-size( 's' );
                    font-weight: font-weight( 'regular' );

                    p {
                        margin-top: theme-spacing( 1 );
                        margin-bottom: theme-spacing( 1 );
                    }

                    p.check-in-out__datetime {
                        margin-top: theme-spacing( 0 );
                        color: theme-color( 'primary' );
                        font-size: font-size( 'm' );
                    }
                }

                .amenities {
                    .amenity {
                        width: 50%;
                        line-height: line-height( 'xl' );
                        margin: theme-spacing( 1 ) 0;
                        color: theme-color( 'success' );
                        font-size: font-size( 's' );
                    }

                    .extra-amenities {
                        width: max-content;
                        cursor: pointer;
                        margin-left: 26px;
                    }

                    .extra-amenities:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .description {
            line-height: line-height( 'xxxl' );
            margin-top: theme-spacing( 6 );
            color: theme-color( 'black' );
            font-size: font-size( 's' );
        }
    }

    .extra-amenities-menu {
        padding: theme-spacing( 3 );
        background: theme-color( 'white' );
        box-shadow: 0 2px 6px rgba( 0, 0, 0, 0.25 );
        border-radius: border-radius( 'xs' );

        p:last-child {
            margin-bottom: theme-spacing( 0 );
        }
    }

    @include mobile-only {
        .package-offer__hotel-details {
            .details {
                flex-direction: column;

                .image-masonry {
                    width: 100%;
                    margin-right: 0;
                    min-height: 250px;
                }

                .hotel-info {
                    position: unset;
                }
            }

            margin-bottom: theme-spacing( 4 );
        }
    }
</style>
