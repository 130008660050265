<template>
    <div v-if="hasInfo" class="package-offer__additional-notes u-width-100">
        <div
            v-if="rateComments"
            class="details">
            <p>{{ $t('rateComments.title') }}</p>
            <!-- eslint-disable-next-line vue/no-v-html  -->
            <p v-html="rateComments"></p>
        </div>
        <div
            v-if="payLaterFee"
            class="details">
            <p>{{ $t('payLateFee.title') }}</p>
            <p>{{ $t('payLateFee.content', { payLaterFee: payLaterFeeCurrency }) }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdditionalNotes',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.packageOffer.thePackageOffer._components.additionalNotes'
        },
        props: {
            rateComments: {
                type: String,
                default: ''
            },
            payLaterFee: {
                type: Number,
                default: 0
            },
            currency: {
                type: String,
                default: ''
            }
        },
        computed: {
            payLaterFeeCurrency( ) {
                return this.$options.filters.currencyFilter( this.payLaterFee, this.currency );
            },
            hasInfo( ) {
                return !!this.rateComments || this.payLaterFee;
            }
        }
    };
</script>

<style lang="scss">
    @import '@scssVariables';
    @import '@scssMixins';

    .package-offer__additional-notes {
        padding: theme-spacing( 2, 4 );
        border: 1px solid theme-color( 'septenary' );
        margin-top: theme-spacing( 4 );
        border-radius: border-radius( 'xs' );

        .details {
            padding-top: theme-spacing( 2 );
            padding-bottom: theme-spacing( 2 );

            p {
                line-height: line-height( 'xxxl' );
                margin-bottom: theme-spacing( 2 );
                color: #262626;
                font-size: font-size( 'l' );
                font-weight: font-weight( 'large' );
            }

            p:last-child {
                line-height: line-height( 'xxxl' );
                color: theme-color( 'black' );
                font-size: font-size( 's' );
                font-weight: unset;
            }
        }
    }
</style>